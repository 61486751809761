:root {
  --whiteClr: white;
  --blackClr: black;
  --redClr: red;
}

:root .light-new {
  --whiteBlack: white;
  --whiteBlackOpp: black;
  --btnClr: #00dbae;
  --resendClr: #00dbae;
  --chatPopupBg: #95ea97;
  --chatsellPopupBg : #ee8181;

  --color-1: #000000;
  --border-color-1: #bbbbbb;
  --background-color-1: rgb(250, 250, 250);
  --background-color-2: #ffffff;
  --background-color-3: rgb(245, 245, 245);
  --background-color-4: rgb(243, 243, 243);
  --border-color-2: #dbdbdb;
}

:root .dark-new {
  --whiteBlack: black;
  --whiteBlackOpp: white;
  --btnClr: #00dbae;
  --resendClr: #00dbae;
  --chatPopupBg: #fef6d8;

  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #14161a;
  --background-color-2: #303236;
  --background-color-3: #14161a;
  --background-color-4: rgb(15, 15, 15);
  --border-color-2: #303236;
}

:root .blue-whale {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #0c495c;
  --background-color-2: #29677a;
}

:root .blue-dark {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #36424d;
  --background-color-2: #45505a;
}

:root .brown {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #373737;
  --background-color-2: #686868;
}

:root .dark-black {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #1d1d1d;
  --background-color-2: #111010;
}